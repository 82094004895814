import React from "react"
import GitHubButtons from "./githubbuttons"

const Introduction = () => {
  return (
    <div>
      <h1>Ope, looks like you ran into me.</h1>
      <p>Since you are here, check out some of the articles I have written.</p>
    </div>
  )
}

export default Introduction